<template>
  <div>
    <div class="download-search">
      <div class="row">
        <div class="col-sm-6 col-md-3 col-lg-3">
          <div class="lead-search sourceby">
            <div class="form-group">
              <label>Agent</label>
              <!-- <input
                type="search"
                v-model="filterSearch.search"
                class="form-control ml-0"
                id="exampleInputEmail1"
                placeholder="Seach by agent or team "
              />
              <img
                class="img-fluid per-sericon"
                src="../../assets/img/search.svg"
                alt=""
              /> -->
              <SingleSelect
                v-on:change="filterData"
                :searchable="true"
                id="a_usersadss"
                textColumn="text"
                placeholder="Select Agent"
                v-model="agent"
                :options="allEmployees"
              ></SingleSelect>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4">
          <div class="lead-search">
            <div class="form-group">
              <label>Status</label>
              <select
                class="form-control"
                v-model="filterSearch.status"
                @change="updateData('status')"
              >
                <option value="">All</option>
                <option value="0">Pending</option>
                <option value="1">Completed</option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-5 col-lg-5">
          <div class="sale-timeline">
            <div class="timeline">
              <button
                type="button"
                v-bind:class="
                  $store.state.alertFilter == 'today' ? 'current' : ''
                "
                v-on:click="updateData('today')"
              >
                <span class="today">Today</span>
              </button>
              <button
                type="button"
                v-bind:class="
                  $store.state.alertFilter == 'month' ? 'current' : ''
                "
                v-on:click="updateData('month')"
              >
                <span class="today">This Month</span>
              </button>
              <button
                type="button"
                v-bind:class="
                  $store.state.alertFilter == 'custom' ? 'current' : ''
                "
              >
                <date-range-picker
                  :opens="'left'"
                  :ranges="false"
                  :locale-data="{ format: 'yyyy-mm-dd' }"
                  v-model="filterSearch.customDate"
                  @update="updateData('custom')"
                ></date-range-picker
                ><span class="month">Custom Date</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- download-links -->

    <!-- leads-table-area  -->
    <div class="leads-table-area addcustomer-table alertss task-table">
      <table class="display" id="alerts_table">
        <thead>
          <tr>
            <th>Alert ID</th>
            <th>Alert Date</th>
            <th>Customer Name</th>
            <th>Sales Rep</th>
            <th>BDC Rep</th>
            <th>Completed By</th>
            <th>Alert Description</th>
            <th>Status</th>

            <!-- <th>Date</th>
            <th>Time</th>
            <th>Agents</th>
            <th>Leadsheet</th>
            <th>Status</th>
            <th>Alert</th> -->
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <!-- leads-table-area  -->
  </div>
</template>


<script>
import axios from "axios";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import SingleSelect from "../helpers/singleSelect";
import Swal from "sweetalert2";
export default {
  name: "Alerts",
  components: {
    DateRangePicker,
    SingleSelect,
  },
  data() {
    return {
      agent: {},
      alerts: null,
      filterSearch: {
        duration: this.$store.state.alertFilter,
        agent: "",
        search: "",
        customDate: {},
        from: "",
        to: "",
        status: "",
      },
      allEmployees: [],
    };
  },
  created() {
    if (
      this.$route.query.duration != undefined &&
      this.$route.query.duration != null &&
      this.$route.query.duration != "undefined" &&
      this.$route.query.duration != ""
    ) {
      this.filterSearch.duration = this.$route.query.duration;
    }
    if (this.$route.query.status) {
      this.filterSearch.status = 0;
    }
    this.getAllEmployees();
  },
  methods: {
    getAllEmployees() {
      axios
        .get("all_employees", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            if (res.data.success.length > 0) {
              var dd = [];
              $.each(res.data.success, function (key, value) {
                dd.push({
                  id: value.id,
                  text: value.name,
                });
              });
              this.allEmployees = dd;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filterData() {
      this.filterSearch.agent = this.agent.id;
      $("#alerts_table").dataTable().fnDraw();
    },
    updateData(val) {
      if (val == "custom") {
        let startDate = this.filterSearch.customDate.startDate;
        let endDate = this.filterSearch.customDate.endDate;
        this.$store.state.from = moment(new Date(startDate)).format(
          "YYYY-MM-DD"
        );
        this.$store.state.to = moment(new Date(endDate)).format("YYYY-MM-DD");
        this.$store.commit("updateAlertFilter", "custom");
      } else if (val == "today") {
        this.$store.commit("updateAlertFilter", "today");
      } else if (val == "month") {
        this.$store.commit("updateAlertFilter", "month");
      }
      $("#alerts_table").dataTable().fnDraw();
    },
    updateStatus(id, lead_id) {
      axios
        .get("update_alert_status/" + id + "/" + lead_id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.status) {
            $("#pend" + id).html('<a class="comp" href="#">Completed</a>');
            $(".dtr-data #pend" + id).html(
              '<a class="comp" href="#">Completed</a>'
            );
            //$("#alerts_table").dataTable().fnDraw();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    "filterSearch.search": function () {
      this.updateData();
    },
  },
  mounted() {
    let v = this;
    $("#alerts_table")
      .addClass("nowrap")
      .dataTable({
        responsive: true,
        fixedHeader: true,
        processing: true,
        serverSide: true,
        stateSave: true,
        order: [[0, "desc"]],
        ajax: {
          url: axios.defaults.baseURL + "alerts_list",
          type: "POST",
          data: function (d) {
            d.search = v.filterSearch.search;
            d.duration = v.$store.state.alertFilter;
            d.from = v.$store.state.from;
            d.to = v.$store.state.to;
            d.status = v.filterSearch.status;
            d.agent = v.filterSearch.agent;
          },
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        },
        columnDefs: [
          {
            targets: [3, 4, 5],
            orderable: false,
          },
        ],
      });

    $("tbody").on("click", ".cus_name", function () {
      var id = $(this).data("id");
      if (id !== undefined && id != "undefined" && id != null && id != "") {
        v.$router.push("/lead-detail/" + id);
      }
    });
    $("tbody").on("click", ".changeStatus", function () {
      var id = $(this).data("id");
      var lead_id = $(this).data("leadid");
      if (id !== undefined && id != "undefined" && id != null && id != "") {
        Swal.fire({
          text: "Are you sure? you want to change status of this alert?",
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: "Ok",
          confirmButtonColor: "#19cd9d",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            v.updateStatus(id, lead_id);
          }
        });
        // v.$confirm({
        //   title: `Are you sure?`,
        //   message: "you want to change status of this alert?",
        //   button: {
        //     no: "No",
        //     yes: "Yes",
        //   },
        //   callback: (confirm) => {
        //     if (confirm) {
        //       v.updateStatus(id, lead_id);
        //     }
        //   },
        // });
      }
    });
  },
};
</script>

<style scoped>
@import "../../assets/css/dataTables.css";
.per-sericon {
  position: absolute;
  top: 7px;
  right: 24px;
  height: 20px;
  background: #fff;
  cursor: pointer;
}
.sourceby .customMS {
  width: 100%;
  margin-left: 15px;
}
.sourceby .customMS-drop h4 {
  border-radius: 30px;
  font-size: 12px;
  border: none;
  box-shadow: 0px 4px 8px rgba(49, 81, 161, 0.08);
}
.sourceby .show-options {
  top: 33px !important;
}
.sourceby .customMS-search input {
  margin: 0;
}
</style>

